import {
  Directive,
  HostListener,
  Output,
  Self,
  EventEmitter,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { parseErrorObject } from '@et/utils';
import { catchError, map } from 'rxjs';
import {
  ProfileNameCompanyByEmailDto,
  ProfilesService,
} from '@escrowtab/v2profile-api-client';

@Directive({
  selector: '[interfaceFindAgentByEmail]',
})
export class FindAgentByEmailDirective {
  @Output() profile = new EventEmitter<ProfileNameCompanyByEmailDto>();
  @Output() agentName = new EventEmitter<string>();
  @Output() agentCompanyId = new EventEmitter<string>();
  @Output() agentApiError = new EventEmitter<string>();
  @Output() agentUserId = new EventEmitter<string>();

  private lastCheckedEmail: string | undefined;
  private lastProfile: ProfileNameCompanyByEmailDto | undefined;
  private lastAgentName: string | undefined;
  private lastCompanyId: string | undefined;
  private lastUserId: string | undefined;

  constructor(
    @Self() private ngControl: NgControl,
    private profileService: ProfilesService,
  ) {}

  /**
   * It takes email from input field and emits agent name and company found by this email
   *
   * @memberOf FindAgentByEmailDirective
   */
  @HostListener('focusout')
  onFocusOut() {
    const email = this.ngControl.value;

    // Simple email field validation
    if (!email) {
      return;
    }

    // Emit agent name and company id if the email was already checked
    if (this.lastCheckedEmail && this.lastCheckedEmail === email) {
      this.agentName.emit(this.lastAgentName);
      this.agentCompanyId.emit(this.lastCompanyId);
      this.agentUserId.emit(this.lastUserId);
      this.profile.emit(this.lastProfile);
      return;
    }

    this.getAgencyByEmail(email);
  }

  /**
   * 1. It receives agent email and makes an API call to get agency info by emil.
   * 2. It emits found agent name and company id or error
   * @param {String} email - Email
   *
   * @memberOf OrderInfoComponent
   */
  private getAgencyByEmail(email: string) {
    this.profileService
      .getProfileSummaryByEmail({ email })
      .pipe(
        map((res) => res.data),
        catchError((error) => {
          this.lastCheckedEmail = undefined;
          const errorMsg = parseErrorObject(error);
          this.agentApiError.emit(errorMsg);
          throw error;
        }),
      )
      .subscribe((profile) => {
        if (!profile) {
          this.lastCheckedEmail = undefined;
          const errorMsg = parseErrorObject('Profile not foind');
          this.agentApiError.emit(errorMsg);
          return;
        }
        // Save email, name companyId and userId
        this.lastCheckedEmail = email;
        this.lastAgentName = `${profile.firstName} ${profile.lastName}`;
        this.lastCompanyId = profile.companyId;
        this.lastUserId = profile.profileId;
        this.lastProfile = profile;
        // Emit name, companyId and userId
        this.agentName.emit(this.lastAgentName);
        this.agentCompanyId.emit(this.lastCompanyId);
        this.agentUserId.emit(this.lastUserId);
        this.profile.emit(this.lastProfile);
      });
  }
}
