import { ProfileDto } from '@escrowtab/v2profile-api-client';
import {
  AADUserWithClaims,
  CompanyData,
  NotaryOnboarding,
  Profile,
  StampWithImage,
} from '@et/typings';

export enum ProfileActionsType {
  SETP_ROFILE = '[SET_PROFILE] Set users profile',
  SET_AAD_USER_WITH_CLAIMS = '[SET_PROFILE] Set aad user with claims profile',
  GET_FULL_PROFILE = '[SET_PROFILE] get full profile from api',
  SET_FULL_PROFILE = '[SET_PROFILE] set full profile from api',
  UPDATE_FULL_PROFILE = '[UPDATE_PROFILE] update full profile',
  GET_COMPANY = '[SET_PROFILE] get company from api',
  SET_COMPANY = '[SET_PROFILE] set company from api',
  SET_NOTARY_STAMP = '[SET_PROFILE] Set notary stamps in store',
  SET_NOTARY_STAMP_AS_PRIMARY = '[SET_PROFILE] Set notary stamp in store as primary',
  SET_NOTARY_ONBOARDING = '[ONBOARDING] Set notary onboarding',
  CLEAR_NOTARY_ONBOARDING = '[ONBOARDING] Clear notary onboarding',
}

export class SetAADUserWithClaims {
  static readonly type = ProfileActionsType.SET_AAD_USER_WITH_CLAIMS;
  constructor(public profile: Partial<AADUserWithClaims>) {}
}
export class GetFullProfile {
  static readonly type = ProfileActionsType.GET_FULL_PROFILE;
  constructor(public profile: Partial<AADUserWithClaims>) {}
}
export class SetFullProfile {
  static readonly type = ProfileActionsType.SET_FULL_PROFILE;
  constructor(public profile: Profile) {}
}

export class UpdateFullProfile {
  static readonly type = ProfileActionsType.UPDATE_FULL_PROFILE;
  constructor(public profile: Partial<Profile>) {}
}

export class SetNotaryStamp {
  static readonly type = ProfileActionsType.SET_NOTARY_STAMP;
  constructor(public stamp: StampWithImage[]) {}
}

export class SetNotaryStampAsPrimary {
  static readonly type = ProfileActionsType.SET_NOTARY_STAMP_AS_PRIMARY;
  constructor(public stampId: string) {}
}

export class SetNotaryOnboarding {
  static readonly type = ProfileActionsType.SET_NOTARY_ONBOARDING;
  constructor(public payload: NotaryOnboarding) {}
}

export class ClearNotaryOnboarding {
  static readonly type = ProfileActionsType.CLEAR_NOTARY_ONBOARDING;
}

export class GetCompany {
  static readonly type = ProfileActionsType.GET_COMPANY;
  constructor(public companyId: string) {}
}

export class SetCompany {
  static readonly type = ProfileActionsType.SET_COMPANY;
  constructor(public company: CompanyData) {}
}
