import {
  IPublicClientApplication,
  PublicClientApplication,
  BrowserCacheLocation,
  LogLevel,
  InteractionType,
} from '@azure/msal-browser';
import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import { environment } from '@et/environment';
/* This is a simple check to see if the user is using Internet Explorer. */
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;
/**
 * It takes a log level and a message and logs the message to the console
 * @param {LogLevel} logLevel - The log level of the message.
 * @param {string} message - The message to log.
 */
function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}
/**
 * It creates an instance of the MSAL.PublicClientApplication class.
 * @returns The `PublicClientApplication` object.
 */
function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.aad.clientId,
      authority: environment.aad.b2cPolicies.authorities.signIn.authority,
      redirectUri: '/',
      postLogoutRedirectUri: '/',
      knownAuthorities: environment.aad.b2cPolicies.authorityDomain,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: !environment.production,
      },
    },
  });
}

/**
 * It creates a configuration object that will be used by the MSALInterceptor.
 * @returns The `MSALInterceptorConfigFactory` returns an object that is used to configure
 * the MSALInterceptor.
 */
function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string> | null>();
  protectedResourceMap.set(
    `${environment.apiServer.domain}/profile/*/access-token/`,
    null,
  );
  protectedResourceMap.set(
    environment.apiServer.domain,
    environment.apiServer.aadScope,
  );

  protectedResourceMap.set(
    environment.apiOrders.domain,
    environment.apiOrders.aadScope,
  );

  protectedResourceMap.set(
    environment.apiSecureLinks.domain + '/*/auth',
    environment.apiSecureLinks.aadScope,
  );

  protectedResourceMap.set(
    environment.apiSecureLinks.domain + '/SecureLinks/GetTwilioToken',
    environment.apiSecureLinks.aadScope,
  );

  protectedResourceMap.set(
    environment.docio.domain,
    environment.docio.aadScope,
  );

  protectedResourceMap.set(
    environment.docPrepService.domain,
    environment.docPrepService.aadScope,
  );

  protectedResourceMap.set(
    environment.profilev2.domain,
    environment.profilev2.aadScope,
  );

  protectedResourceMap.set(
    environment.auditService.domain,
    environment.auditService.aadScope,
  );

  protectedResourceMap.set(
    environment.packetService.domain,
    environment.packetService.aadScope,
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

/**
 * It returns a configuration object that will be used by the MsalGuard.
 * @returns The `MsalGuardConfig` object is being returned.
 */
function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [],
    },
    loginFailedRoute: '/auth/login-failed',
  };
}

/* export dependencies to the `@Injectable` decorator. required for Azure AAD*/
export const MSALProviders = [
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory,
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory,
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory,
  },
];
